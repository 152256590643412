window.Isotope = require('isotope-layout/dist/isotope.pkgd.min.js');

import { AdobeAnalyticsUtil } from '/Foundation/Common/code/Views/Foundation/Common/AdobeAnalytics/AdobeAnalyticsUtil';

let isotope = undefined;
const productlisting = document.querySelector('.product-listing');
const productListContainer = document.querySelector('.product-list-container');
const products = Array.from(productlisting.querySelectorAll('.isotope-item'));
const sortDropDown = productlisting.querySelector('.sort-product-dropdown select');
const sortSection = productlisting.querySelector('.sorting-section');
const sortRadioButtons = sortSection?.querySelectorAll('input[type="radio"]');
const inputs = productlisting.querySelectorAll('.filter-checklist .form-check input');
const colorCheckboxes = productlisting.querySelectorAll('.filter-color .color-checkbox label input');
const clearFilterBtn = document.getElementById('clear-filters-btn');
const filterSections = productlisting.querySelectorAll('.filter-section');
const filtersList = productlisting.querySelector('.filter-list');
const filtersAppliedList = document.querySelector('.filters-applied');
const numberOfFilteredItemsLabels = document.querySelectorAll('.filter-total-amount');
const productListingContainer = document.querySelector('.isotope-container');
const clearFiltersText = clearFilterBtn?.innerText;
const introText = document.querySelector('.intro-text');
const showMoreTextBtn = document.querySelector('.read-more-btn');
const menu = productlisting.querySelector('.filter-menu.left-slide-in-menu');
const filterMenuBtn = productlisting.querySelector('.toggle-filter-menu');
const hideFilterInfoBtns = productlisting.querySelectorAll('.hide-filter-info-overlay');
const filterInfoIcons = productlisting.querySelectorAll('.frg-icon-Information-icon');
const filterInfoMenu = productlisting.querySelector('.filter-infotext-menu');
const filterCloseBtns = productlisting.querySelectorAll('.close-btn');
const filterHeaders = productlisting.querySelectorAll('.filter-header');
const numOfItemsToShow = productlisting.querySelector('.num-items-to-show');
const backdrop = productlisting.querySelector('.product-listing-left-slide-in-menu-backdrop');
const toggleFilterDesktopBtn = productlisting.querySelector('.toggle-filter-btn');
const hideFilterText = productlisting.querySelector('.hide-filter-text');
const showFilterText = productlisting.querySelector('.show-filter-text');
const showMoreBtn = productlisting.querySelector('.show-more-btn');
const productIncrement = 12;
let noOfVisibleProducts = productIncrement;
let noOfProductsMatchingFilters = 0;
const isExtranet = window._THULEDATA.isExtranet;

const filters = {
  defaultProductListing: productListingContainer.innerHtml,
  filtersAppliedNames: [],
  filtersApplied: {},
  filtersAppliedIsotope: '*',
  sortingEnabled: sortSection != null,
};

export const handleIntro = (lineHeight, introTextElem, btnElem, maxLinesToShow) => {
  if (introTextElem) {
    const showButton = introTextElem.firstChild?.getBoundingClientRect()?.height > lineHeight * maxLinesToShow;
    if (showButton) {
      introTextElem.classList.add('closed');
      btnElem.classList.remove('d-none');
    } else {
      introTextElem.classList.remove('closed');
      btnElem.classList.add('d-none');
    }
  }
};

const showMoreText = () => {
  showMoreTextBtn.classList.add('d-none');
  introText.classList.remove('closed');
};

const initIsotope = () => {
  isotope = new Isotope(productListingContainer, {
    itemSelector: '.isotope-item',
    layoutMode: 'fitRows',
    fitRows: {
      gutter: '.gutter-sizer',
    },
    resizable: false,
    getSortData: {
      rating: '[data-sort-rating] parseInt',
      pricehighlow: '[data-sort-price] parseInt',
      pricelowhigh: '[data-sort-price] parseInt',
      newest: '[data-sort-new] parseInt',
      fit: '[data-sort-fit] parseInt',
      defaultsort: '[data-sort-default-position] parseInt',
    },
    filter: function (itemElem) {
      var hasFilter = false;
      if (filters.filtersAppliedIsotope == '*') hasFilter = true;
      else if (!itemElem.classList.contains('content-block')) {
        var appliedFilters = filters.filtersAppliedIsotope.split(',');
        var itemFilters = itemElem.dataset.itemfilters
          ?.split(' ')
          .filter((i) => i)
          .join('.');

        for (var i = 0; i < appliedFilters.length; i++) {
          var af = appliedFilters[i].split('.').filter((f) => f);
          var matchesAll = true;
          for (var j = 0; j < af.length; j++) {
            var filterClass = af[j].trim().replace('.', '');
            if (itemFilters?.indexOf(filterClass) == -1) {
              matchesAll = false;
              break;
            }
          }

          if (matchesAll) {
            hasFilter = true;
            break;
          }
        }
      } else itemElem.classList.add('d-none');

      if (hasFilter && itemElem.classList.contains('content-block')) itemElem.classList.remove('d-none');
      return hasFilter;
    },
  });
  window.resetIsotopeLayout = setCardHeight;
};

const setupSorting = () => {
  var listItems = productListingContainer.querySelectorAll('.isotope-item');
  listItems.forEach((item) => {
    var price = item.querySelector('.ga-discount-price')?.innerText;
    if (isNaN(price) || Number(price) <= 0) {
      price = item.querySelector('.ga-price')?.innerText;
    }
    var newProduct = item.querySelector('.new');
    var newFlag = newProduct != null;

    item.dataset.sortPrice = price ? price.trim() : 0;
    item.dataset.sortNew = newFlag ? 1 : 0;
    item.dataset.sortFit = 0;
    const indicator = item.querySelector('.fit-indicator');
    if (indicator && indicator.classList.contains('confirmed')) {
      item.dataset.sortFit = 1;
    }
  });

  if (sortSection != null) {
    sortDropDown.addEventListener('change', function () {
      if (window.innerWidth > 991) {
        sortSection.querySelector('input:checked').checked = false;
        Array.from(sortRadioButtons).find(
          (x) => x.dataset.sortValue === sortDropDown.options[sortDropDown.selectedIndex].dataset.sortValue
        ).checked = true;
        applySort(sortDropDown.options[sortDropDown.selectedIndex].dataset.sortValue);
      }
    });

    sortRadioButtons.forEach((option) => {
      option.addEventListener('change', function () {
        if (window.innerWidth < 992) {
          sortDropDown.value = option.dataset.sortValue;
          applySort(option.dataset.sortValue);
        }
      });
    });

    sortSection.addEventListener('triggerSort', function (e) {
      applySort(e.detail, true);
    });
    sortSection.setAttribute('data-triggersort', true);
  }
};

function applySort(sortValue, reInit) {
  isotope.updateSortData();
  products.forEach((product) => {
    product.classList.remove('d-none');
  });

  if (!isExtranet) {
    isotope.arrange({
      sortBy: sortValue === 'fit' ? ['fit', sortDropDown.value] : [sortValue],
      sortAscending: {
        rating: false,
        pricehighlow: false,
        newest: false,
        fit: false,
        pricelowhigh: true,
      },
    });
  } else {
    const filteredProducts = getFilteredItems();
    let items = [];
    filteredProducts.forEach((product) => {
      items.push(product.querySelector('.product-list-item'));
    });
    const fetchM3Prices = new CustomEvent('fetchPricesOnFilter', {
      detail: items,
    });
    window.dispatchEvent(fetchM3Prices);
    setTimeout(() => {
      setInitialNoOfItems(reInit);
      isotope.arrange({
        sortBy: sortValue === 'fit' ? ['fit', sortDropDown.value] : [sortValue],
        sortAscending: {
          rating: false,
          pricehighlow: false,
          newest: false,
          fit: false,
          pricelowhigh: true,
        },
      });
    }, 1000);
  }
}

//Necessary to keep an even height for content blocks & normal product cards on desktop
const setCardHeight = () => {
  const allItems = productListingContainer.querySelectorAll('.isotope-item');
  let tallestHeight = 0;
  Array.from(allItems).forEach((item) => {
    let elementHeight = item.offsetHeight;
    if (elementHeight > tallestHeight) tallestHeight = elementHeight;
  });
  Array.from(allItems).forEach((item) => {
    item.style.height = tallestHeight + 'px';
  });
};

const resetCardHeight = () => {
  const allItems = productListingContainer.querySelectorAll('.isotope-item');
  Array.from(allItems).forEach((item) => {
    item.style.height = 'auto';
  });
};

const bindEvents = () => {
  clearFilterBtn?.addEventListener('click', clearAllFilters);

  inputs.forEach((cb) => {
    cb.addEventListener('change', (e) => {
      e.stopPropagation();
      if (e.target.type === 'radio' && e.target.checked) {
        // To enable de-selecting radio buttons
        e.target.dataset.prevstate = true;
        var filterSection = e.target.closest('.filter-section');
        var radiobuttons = filterSection.querySelectorAll('input[type="radio"]');
        radiobuttons.forEach((rb) => {
          if (rb != e.target) rb.dataset.prevstate = false;
        });
      }
      standardInputFilter(e.target);
    });

    cb.addEventListener('click', (e) => {
      e.stopPropagation();
      if (e.target.type === 'radio' && e.target.checked && e.target.dataset.prevstate == 'true') {
        // To enable de-selecting radio buttons
        e.target.checked = false;
        e.target.dataset.prevstate = false;
        standardInputFilter(e.target);
      }
    });
  });

  colorCheckboxes.forEach((cb) => {
    cb.addEventListener('change', (e) => {
      e.stopPropagation();
      colorFilter(e.target);
    });
  });

  document.addEventListener('click', function (e) {
    if (
      e.target.parentElement?.parentElement != null &&
      e.target.parentElement.parentElement.classList.contains('filter-applied')
    )
      removeFilter(e.target.parentElement.parentElement);
  });

  if (introText) {
    window.addEventListener('resize', () => {
      handleIntro(24, introText, showMoreTextBtn, 2);
    });
  }

  window.addEventListener('resize', () => {
    resetCardHeight();
    if (window.innerWidth > 991) setCardHeight();
  });

  showMoreTextBtn?.addEventListener('click', () => {
    showMoreText();
  });

  filterMenuBtn.addEventListener('click', function () {
    menu.classList.add('wrapper-visible');
    document.querySelector('html').classList.add('no-scroll');
    document.querySelector('body').classList.add('no-scroll');
  });

  filterCloseBtns.forEach((btn) => {
    btn.addEventListener('click', function () {
      closeFilterOverlay();
    });
  });

  hideFilterInfoBtns.forEach((btn) => {
    btn.addEventListener('click', function () {
      closeFilterInfoOverlay();
    });
  });

  filterInfoIcons.forEach((icon) => {
    icon.addEventListener('click', function (e) {
      showFilterExplanation(e.target);
    });
  });

  filterHeaders?.forEach((header) => {
    header.addEventListener('click', () => toggleFilterSection(header));
  });

  backdrop.addEventListener('click', () => {
    closeFilterInfoOverlay();
  });

  toggleFilterDesktopBtn?.addEventListener('click', () => {
    if (menu.classList.contains('d-lg-none')) {
      menu.classList.remove('d-lg-none');
      productListingContainer.classList.remove('no-filter');
      hideFilterText.classList.remove('d-none');
      showFilterText.classList.add('d-none');
    } else {
      menu.classList.add('d-lg-none');
      productListingContainer.classList.add('no-filter');
      hideFilterText.classList.add('d-none');
      showFilterText.classList.remove('d-none');
    }
    initIsotope();
    if (window.innerWidth > 991) setCardHeight();
    var customEvent = new Event('changePLPLayout');
    window.dispatchEvent(customEvent);
  });

  showMoreBtn?.addEventListener('click', () => {
    showMore();
  });
};

const toggleFilterSection = (header) => {
  const collapsibleSection = header.parentElement.querySelector('.collapsible-section');
  const isCollapsed = collapsibleSection.classList.contains('collapsed');
  if (isCollapsed) {
    collapsibleSection.classList.remove('collapsed');
    header.querySelector('.expand-icon').classList.add('d-none');
    header.querySelector('.collapse-icon').classList.remove('d-none');
  } else {
    collapsibleSection.classList.add('collapsed');
    header.querySelector('.collapse-icon').classList.add('d-none');
    header.querySelector('.expand-icon').classList.remove('d-none');
  }
};

const updateHash = () => {
  const checkedFilters = Array.from(productlisting.querySelectorAll('.filter-section input:checked'))
    .map((item) => item.id)
    .join(',');

  if (checkedFilters != '') {
    history.replaceState(
      '',
      document.title,
      window.location.pathname + window.location.search + '#filter=' + encodeURIComponent(checkedFilters)
    );
  } else {
    history.replaceState('', document.title, window.location.pathname + window.location.search);
  }

  setNumOfSelectedFiltersPerSection();
};

const setNumOfSelectedFiltersPerSection = () => {
  filterSections.forEach((section) => {
    const allChecked = section.querySelectorAll('input:checked');
    const numSelectedElem = section.querySelector('.num-selected');
    if (allChecked.length > 0) {
      numSelectedElem.innerHTML = `(${allChecked.length})`;
    } else {
      numSelectedElem.innerHTML = '';
    }
  });
};

/* Display items that exist per filter in multiselects with the current filtering applied */
const displayItemsPerFilter = (filterGroupUsed, isPageLoad) => {
  var allItems = getAllItems();

  // for each filtergroup displayed on the page
  filterSections.forEach((fs) => {
    var filterGroupName = fs.querySelector('.filter-headline').innerText;
    var inputsInGroup = fs.querySelectorAll('input');
    inputsInGroup.forEach((input, i) => {
      var numOfItems = calcNumItemsPerFilter(input, filterGroupName, allItems);
      var filterAmountElem = input.parentElement.querySelector('.filter-amount');
      if (filterAmountElem != null) filterAmountElem.innerHTML = numOfItems;

      if (fs.querySelector('.filter-color') != null) {
        numOfItems || input.checked
          ? input.closest('.color-checkbox').classList.remove('disabled')
          : input.closest('.color-checkbox').classList.add('disabled');
      } else {
        numOfItems || input.checked
          ? input.parentElement.parentElement.classList.remove('disabled')
          : input.parentElement.parentElement.classList.add('disabled');
      }

      if (numOfItems == 0 && isPageLoad) {
        input.parentElement.parentElement.remove();
      }
    });

    if (isPageLoad) {
      var inputsInGroup = fs.querySelectorAll('input');
      if (inputsInGroup.length == 0) fs.remove();
    }
  });

  if (isPageLoad) {
    filtersList?.querySelector('.remove-after-load').remove();
    filtersList?.classList.remove('d-none');
  }
};

/* Calculate number of items per filter that will be displayed if you apply a filter */
const calcNumItemsPerFilter = (input, currentFilterGroup, allItems) => {
  var filterValue = input.dataset.filter;
  var numItemsForFilter = 0;

  allItems.forEach((item, i) => {
    // if item does not have this filter
    var filterValues = filterValue.split(',');
    var itemMatchesAnyFilter = false;
    filterValues.forEach((fv) => {
      if (item.dataset.itemfilters?.indexOf(fv) > -1) {
        itemMatchesAnyFilter = true;
      }
    });

    if (!itemMatchesAnyFilter) return true; // return true to continue to next iteration in loop

    var hasFilters = true;

    // go through all applied filters
    for (var filterGroup in filters.filtersApplied) {
      if (filterGroup === currentFilterGroup) {
        continue;
      }
      var filterGroupArr = filters.filtersApplied[filterGroup];
      if (filterGroupArr.length > 0) {
        var hasFilterInThisGroup = false;
        for (i = 0; i < filterGroupArr.length; i++) {
          var withoutDot = filterGroupArr[i].substring(1, filterGroupArr[i].length);
          if (item.dataset.itemfilters.indexOf(withoutDot) > -1) {
            hasFilterInThisGroup = true;
            break;
          }
        }

        // if item does not have any of the filters in the current filter group, don't include
        if (!hasFilterInThisGroup) {
          hasFilters = false;
        }
      }
    }

    if (hasFilters) {
      numItemsForFilter++;
    }
  });

  return numItemsForFilter;
};

const sendAnalyticsForCategory = () => {
  var categoryName = document.getElementById('HiddenCategoryName').value;
  if (typeof google !== 'undefined') google.productlistingevents?.selectFilter('Categories', categoryName);
};

const sendAnalyticsForSubCategory = () => {
  var subCategoryName = document.getElementById('HiddenSubCategoryName').value;
  if (subCategoryName) {
    if (typeof google !== 'undefined') google.productlistingevents?.selectFilter('SubCategories', subCategoryName);
  }
};

const applyFilters = (filterGroupUsed) => {
  setComboFilters(filters.filtersApplied);
  isotope.arrange();
  updateFiltersAppliedGUI();

  if (isExtranet) {
    const products = getFilteredItems();
    let items = [];
    products.forEach((product) => {
      items.push(product.querySelector('.product-list-item'));
    });
    const fetchM3Prices = new CustomEvent('fetchPricesOnFilter', {
      detail: items,
    });
    window.dispatchEvent(fetchM3Prices);
  }

  setTimeout(function () {
    displayNumItemsFiltered();
    displayItemsPerFilter(filterGroupUsed);
  }, 400);
};

const clearAllFilters = (e) => {
  e.preventDefault();

  filters.filtersAppliedIsotope = '*';

  isotope.arrange();

  filters.filtersAppliedNames = [];
  filters.filtersApplied = [];

  updateFiltersAppliedGUI();
  displayItemsPerFilter();
  displayNumItemsFiltered();
  uncheckAllCheckboxesAndRadiobuttons();
  setupProductBasedOnFilter(null, []);
  updateHash();
};

const uncheckAllCheckboxesAndRadiobuttons = () => {
  inputs.forEach((cb) => {
    cb.checked = false;
  });

  colorCheckboxes.forEach((cb) => {
    cb.checked = false;
  });
};

const reloadPreviousFilters = () => {
  updateFilterList();
  initIsotope();

  inputs.forEach((cb) => {
    standardInputFilter(cb);
  });

  colorCheckboxes.forEach((cb) => {
    colorFilter(cb);
  });
};

const removeFilter = (filterClicked) => {
  var filter = filterClicked.dataset.filter;
  var input = productlisting.querySelector("input[data-filter='" + filter + "']");
  input.checked = false;

  updateFilterList();
  applyFilters();
};

/* go through all checked filters to add or remove filters from filters applied list */
const updateFilterList = () => {
  filterSections.forEach((section) => {
    var groupName = section.querySelector('.filter-headline').textContent;
    var inputs = section.querySelectorAll('input');
    var inclusives = checkAppliedFiltersInCategory(inputs);

    filters.filtersApplied[groupName] = inclusives;
  });
};

/* Filter by using the multi select checkboxes and single select radio buttons */
const standardInputFilter = (filterClicked) => {
  if (isExtranet) {
    products.forEach((product) => {
      product.classList.remove('d-none');
    });
    noOfVisibleProducts = 0;
    noOfProductsMatchingFilters = 0;
  }
  var filterSection = filterClicked.closest('.filter-section');
  if (filterSection == null) return;

  var groupName = filterSection.querySelector('.filter-headline').textContent;
  var inputs = filterSection.querySelectorAll('input');

  var inclusives = checkAppliedFiltersInCategory(inputs);
  filters.filtersApplied[groupName] = inclusives;
  applyFilters(groupName);

  if (filterClicked.checked) {
    var filterClickedName = filterClicked.dataset.filterEnglishName;
    var groupEnglishName = filterSection.querySelector('.filter-headline').dataset.filterEnglishName;
    if (typeof google !== 'undefined') google.productlistingevents.selectFilter(groupEnglishName, filterClickedName);
  }
  if (isExtranet) {
    setTimeout(() => {
      let reInit = false;
      if (sortDropDown) {
        reInit = sortDropDown.selectedIndex === 0;
      }
      setInitialNoOfItems(reInit);
    }, 1000);
  }
};

/* Filter by using the color filter checkboxes */
const colorFilter = (filterClicked) => {
  if (isExtranet) {
    products.forEach((product) => {
      product.classList.remove('d-none');
    });
    noOfVisibleProducts = 0;
    noOfProductsMatchingFilters = 0;
  }
  var filterSection = filterClicked.closest('.filter-section');
  if (filterSection == null) return;

  var groupName = filterSection.querySelector('.filter-headline').textContent;
  var colorcheckboxes = filterSection.querySelectorAll('input');

  var inclusives = checkAppliedFiltersInCategory(colorcheckboxes);
  filters.filtersApplied[groupName] = inclusives;
  applyFilters(groupName);

  if (filterClicked.checked) {
    var filterClickedName = filterClicked.dataset.filterEnglishName;
    var groupEnglishName = filterSection.querySelector('.filter-headline').dataset.filterEnglishName;
    var clickedColorId = filterClicked.dataset.filter;

    if (typeof google !== 'undefined') google.productlistingevents.selectFilter(groupEnglishName, filterClickedName);
    setupProductBasedOnFilter(clickedColorId, inclusives);
  } else setupProductBasedOnFilter(null, inclusives);
  if (isExtranet) {
    setTimeout(() => {
      let reInit = false;
      if (sortDropDown) {
        reInit = sortDropDown.selectedIndex === 0;
      }
      setInitialNoOfItems(reInit);
    }, 1000);
  }
};

const setupProductBasedOnFilter = (colorid, colorfilters) => {
  var jsonstr = document.getElementById('productListItemGroups').value;
  if (!jsonstr) return;

  var productGroupItems = JSON.parse(jsonstr);

  for (var property in productGroupItems) {
    if (productGroupItems.hasOwnProperty(property)) {
      var variants = productGroupItems[property];
      if (variants && variants.length > 1) {
        var image = variants[0];

        colorfilters.forEach((cf) => {
          var filter = cf.replace('.', '');
          variants.forEach((v) => {
            if (v.ColorId.indexOf(filter) > -1 && image.ColorId != colorid) image = v;
          });
        });

        var listitem = productlisting
          .querySelector('.product-image img[data-pimid="' + image.PimId + '"]')
          ?.closest('.product-list-item');
        if (!listitem) continue;
        var listItemsImages = listitem.querySelectorAll('.product-image img');
        listItemsImages.forEach((lii) => {
          lii.classList.add('d-none');
        });

        listItemsImages = listitem.querySelectorAll('.product-image img[data-pimid="' + image.PimId + '"]');
        listItemsImages.forEach((lii) => {
          lii.classList.remove('d-none');
        });

        var colorSelector = listitem.querySelector('.color-selector');
        if (colorSelector) {
          listitem.querySelector('.color-selector li.active')?.classList.remove('active');
          listitem.querySelector('a.tag-manager-color-variation.active')?.classList.remove('active');
          const activeitem = listitem.querySelector(
            '.color-selector a.tag-manager-color-variation[data-pimid="' + image.PimId + '"]'
          );
          if (activeitem) {
            activeitem.classList.add('active');
            activeitem.closest('li').classList.add('active');
            const swatchNameElem = listitem.querySelector('.selected-swatch-name');
            swatchNameElem.textContent = activeitem.getAttribute('title');
          }
        }

        var href = listitem.querySelector('.product-link').href;
        if (href) {
          href = href.substring(0, href.lastIndexOf('/') + 1) + image.ProductUrl;
          listitem.querySelector('.product-link').href = href;
          var rating = listitem.querySelector('.inline-rating a');
          if (rating) rating.href = href;
        }
      }
    }
  }
};

const checkAppliedFiltersInCategory = (inputs) => {
  var inclusives = [];

  inputs.forEach((input) => {
    var filterName = input.value;

    if (input.checked) {
      var filterValue = input.dataset.filter;
      var values = filterValue.split(' ');
      values.forEach((v) => {
        inclusives.push('.' + v);
      });

      if (!filters.filtersAppliedNames.includes(filterName)) {
        filters.filtersAppliedNames[filterName] = { name: filterName, filter: filterValue };
      }
    } else {
      if (filters.filtersAppliedNames[filterName] !== undefined) {
        delete filters.filtersAppliedNames[filterName];
      }
    }
  });

  return inclusives;
};

const updateFiltersAppliedGUI = () => {
  var filtersApplied = filters.filtersAppliedNames;
  filtersAppliedList.innerHTML = '';

  for (var key in filtersApplied) {
    filtersAppliedList.innerHTML +=
      "<div class='btn filter-applied' data-filter='" +
      filtersApplied[key].filter +
      "'><span class='filter-name'><span>" +
      filtersApplied[key].name +
      "</span></span><span class='remove-filter'><i class='frg-icon-Cross'></i></span></div>";
  }

  if (Object.keys(filtersApplied).length > 0) {
    filtersAppliedList.innerHTML += "<div class='btn thule-btn-tertiary clear-filters'>" + clearFiltersText + '</div>';
    productlisting.querySelector('.clear-filters').addEventListener('click', clearAllFilters);
    filtersAppliedList.classList.remove('d-none');
    productListContainer.classList.remove('pt-8');
    productListContainer.classList.remove('pt-lg-9');
  } else {
    filtersAppliedList.classList.add('d-none');
    productListContainer.classList.add('pt-8');
    productListContainer.classList.add('pt-lg-9');
  }

  updateHash();
};

/* Add new filter to isotope filtering */
const setComboFilters = () => {
  var i = 0;
  var comboFilters = [];
  var filtersApplied = filters.filtersApplied;

  for (var prop in filtersApplied) {
    var groupTitle = prop;
    var group = filtersApplied[prop];

    // skip if this group does not have any filters
    if (!group.length) {
      continue;
    }

    if (i === 0) {
      comboFilters = group.slice(0);
    } else {
      var filterSelectors = [];
      // copy to new array
      var groupCombo = comboFilters.slice(0);

      // merge filter groups
      for (var filter = 0; filter < group.length; filter++) {
        for (var j = 0; j < groupCombo.length; j++) {
          filterSelectors.push(groupCombo[j] + group[filter]);
        }
      }

      // apply filter selectors to combo filters for next group
      comboFilters = filterSelectors;
    }
    i++;
  }

  if (comboFilters.length > 0) {
    comboFilters.sort();
    var combinedFiltersValue = comboFilters.join(', ');

    filters.filtersAppliedIsotope = combinedFiltersValue;
  } else {
    filters.filtersAppliedIsotope = '*';
  }
};

const calcNumFilteredItems = () => {
  return getFilteredItems().length;
};

const getFilteredItems = () => {
  return isotope?.getFilteredItemElements();
};

const getAllItems = () => {
  return productListingContainer.querySelectorAll('.isotope-item:not(.content-block)');
};

const displayNumItemsFiltered = () => {
  var numFilteredItems = calcNumFilteredItems();
  numberOfFilteredItemsLabels.forEach((lbl) => {
    lbl.textContent = numFilteredItems;
  });

  if (numOfItemsToShow) numOfItemsToShow.innerHTML = `(${numFilteredItems})`;
};

const onLoad = () => {
  if (getHashFilter() != null) parseAndApplyHashFilter();
  else {
    reloadPreviousFilters();
    if (sortSection != null) {
      var sortValue = document.querySelector('input[name="sorting"]:checked')?.dataset?.sortValue;
      const event = new CustomEvent('triggerSort', { detail: sortValue });
      sortSection.dispatchEvent(event);
    }
  }
};

const getHashFilter = () => {
  var hash = location.hash;
  var matches = hash.match(/filter=([^&]+)/i);
  var hashFilter = matches && matches[1];
  return hashFilter && decodeURIComponent(hashFilter);
};

const parseAndApplyHashFilter = () => {
  var hash = getHashFilter();
  if (hash === null) return;
  var filters = hash.split(',');
  var allCheckboxes = productlisting.querySelectorAll('input[type="checkbox"]');
  const allRadioButton = productlisting.querySelectorAll('input[type="radio"]');

  allCheckboxes.forEach((cb) => {
    if (filters.includes(cb.id)) {
      cb.click();
    }
  });
  allRadioButton.forEach((cb) => {
    if (filters.includes(cb.id)) {
      cb.click();
    }
  });
};

const init = () => {
  if (_THULEDATA.IsEditing) {
    return;
  }

  if (sortSection != null) {
    setupSorting();
  }
  if (window.innerWidth > 991) setCardHeight();
  bindEvents();

  displayItemsPerFilter(null, true);
  sendAnalyticsForCategory();
  sendAnalyticsForSubCategory();
  onLoad();

  contentBlockTracking();

  displayNumItemsFiltered();
};

const contentBlockTracking = () => {
  let contentBlocks = document.querySelectorAll('.content-block');

  for (let block of contentBlocks) {
    block.addEventListener('click', () => {
      let eventObject = {
        name: block.dataset.trackingName,
        link: block.dataset.trackingLink,
      };

      AdobeAnalyticsUtil.DataLayer.TrackEvent('content_block_click', eventObject);
    });
  }
};

const showFilterExplanation = (element) => {
  var text = element.dataset.infotext;
  var header = element.dataset.filtername;

  filterInfoMenu.querySelector('.filter-explanation-filter-name').innerText = header;
  filterInfoMenu.querySelector('.filter-explanation-text').innerHTML = text;

  filterInfoMenu.classList.add('wrapper-visible');
  backdrop.classList.add('active');
  document.querySelector('html').classList.add('no-scroll');
  document.querySelector('body').classList.add('no-scroll');
};

const closeFilterOverlay = () => {
  menu.classList.remove('wrapper-visible');
  document.querySelector('html').classList.remove('no-scroll');
  document.querySelector('body').classList.remove('no-scroll');
};

const closeFilterInfoOverlay = () => {
  filterInfoMenu.classList.remove('wrapper-visible');
  backdrop.classList.remove('active');
  document.querySelector('html').classList.remove('no-scroll');
  document.querySelector('body').classList.remove('no-scroll');
};

const setInitialNoOfItems = (reInit) => {
  noOfVisibleProducts = productIncrement;
  const filteredProducts = getFilteredItems();
  noOfProductsMatchingFilters = filteredProducts?.length;
  const itemsToHide = filteredProducts.slice(productIncrement);
  itemsToHide.forEach((item) => {
    item.classList.add('d-none');
  });
  if (reInit) initIsotope();
  else isotope.layout();
  if (showMoreBtn) {
    toggleShowMoreButton();
  }
};

const showMore = () => {
  const filteredProducts = getFilteredItems();
  const itemsToEnable = filteredProducts.slice(noOfVisibleProducts, noOfVisibleProducts + productIncrement);
  itemsToEnable.forEach((item) => {
    item.classList.remove('d-none');
  });
  noOfVisibleProducts += productIncrement;
  isotope.layout();
  toggleShowMoreButton();
};

const toggleShowMoreButton = () => {
  if (
    (noOfVisibleProducts < products.length && Object.keys(filters.filtersAppliedNames).length === 0) ||
    (Object.keys(filters.filtersAppliedNames).length > 0 && noOfVisibleProducts < noOfProductsMatchingFilters)
  ) {
    showMoreBtn.classList.remove('d-none');
  } else {
    showMoreBtn.classList.add('d-none');
  }
};

if (productlisting !== null) {
  initIsotope();
  if (isExtranet) {
    setInitialNoOfItems(true);
  }
  handleIntro(24, introText, showMoreTextBtn, 2);
}

document.addEventListener('DOMContentLoaded', function () {
  if (productlisting == null) return;
  init();
});
